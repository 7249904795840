import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Politica from "./pages/Politica";
import LoadingPage from "./pages/Loading"


const Home = lazy(() => import("./pages/Home"))
const Produtos = lazy(() => import("./pages/Produtos"))
const Servicos = lazy(() => import("./pages/Servicos"))
const About = lazy(() => import("./pages/About"))
const GrazingTable = lazy(() => import("./pages/GrazingTable"))
const GrazingBox = lazy(() => import("./pages/GrazingBox"))
const PequenoAlmoco = lazy(() => import("./pages/PequenoAlmoco"))
const Wedding = lazy(() => import("./pages/Wedding"))
const Aniversario = lazy(() => import("./pages/Aniversario"))
const Corporativo = lazy(() => import("./pages/Corporativo"))
const Despedida = lazy(() => import("./pages/Despedida"))
const Picnic = lazy(() => import("./pages/Picnic"))
const MiniWedding = lazy(() => import("./pages/MiniWedding"))
const CorteBolo = lazy(() => import("./pages/CorteBolo"))

function App() {

  const [showFallback, setShowFallback] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowFallback(false);
    }, 1000); // Set a minimum timeout duration in milliseconds

    return () => clearTimeout(timeoutId);
  }, []);


  return (
    <div className='App'>
      <Suspense fallback={showFallback ? <LoadingPage /> : null}>
        <NavBar/>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/produtos" element={<Produtos />} />
              <Route path="/servicos" element={<Servicos />} />
              <Route path="/about" element={<About />} />
              <Route path="/weeding-breakfast" element={<Wedding />} />
              <Route path="/aniversario" element={<Aniversario />} />
              <Route path="/corporativo" element={<Corporativo />} />
              <Route path="/despedida-solteiro" element={<Despedida />} />
              <Route path="/picnic" element={<Picnic />} />
              <Route path="/corte-bolo" element={<CorteBolo />} />
              <Route path="/mini-weeding" element={<MiniWedding/>} />
              <Route path="/grazing-table" element={<GrazingTable />} />
              <Route path="/grazing-box" element={<GrazingBox />} />
              <Route path="/pequeno-almoco" element={<PequenoAlmoco />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/politica-de-tratamento-de-dados" element={<Politica />} />
          </Routes>
        <Footer/>
      </Suspense>
    </div>
        
  );
}

export default App;
