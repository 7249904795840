import React, { useState} from 'react';
import logo from "../images/logo.webp";
import { Link } from "react-router-dom";

const NavBar = () => {
    const navLinks=[
        {'name': 'Serviços', 'url':'/servicos', id: 1},
        {'name': 'Produtos', 'url':'/produtos', id: 2},
        {'name': 'Sobre nós', 'url':'/about', id: 3}
    ]
    const [menuOpen, setMenu] = useState(false);
    return(
        <div>
             <div className="flex md:mb-10 overflow-hidden">
                    <div className="w-full mx-auto">
                        <div className="flex items-center justify-center">
                            <Link to="/" className="flex container"><img src={logo} alt="logo" className="w-20 m-4 self-center" /></Link> 
                            <div className="flex justify-end w-full">
                                <ul className= "hidden md:flex flex-row font-light m-10 text-base bg-white">
                                    {navLinks.map(navLink => {
                                        return  <li key={navLink.id}>
                                                    <Link to={navLink.url} className="text-soft-green block ml-10 hover:text-hover-green">{navLink.name}</Link>
                                                </li>
                                    })} 
                                </ul>
                            <button onClick={() => setMenu(!menuOpen)} className='md:hidden flex justify-end mr-3'>
                                {menuOpen ? <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#77ACA2"/>
                                            </svg>
                                        :
                                            <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 18L20 18" stroke="#77ACA2" strokeWidth="2" strokeLinecap="round"/>
                                                <path d="M4 12L20 12" stroke="#77ACA2" strokeWidth="2" strokeLinecap="round"/>
                                                <path d="M4 6L20 6" stroke="#77ACA2" strokeWidth="2" strokeLinecap="round"/>
                                            </svg>
                                }
                            </button>
                            
                            </div>
                        </div>
                        <ul className={menuOpen ? "flex flex-col font-light text-sm bg-soft-green mt-5 mx-auto h-full" : "hidden"}>
                                {navLinks.map(navLink => {
                                    return  <li className='text-center my-5' key={navLink.id}>
                                                <Link onClick={() => setMenu(false)}to={navLink.url} className="text-gray-100 text-center hover:text-gray-300">{navLink.name}</Link>
                                            </li>
                                })} 
                        </ul>
                    </div>
            </div>
              
         
        </div>
       
    );
    
}

export default NavBar;