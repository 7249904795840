import React from "react";

const Politica = () =>{
    return(
        <div className="mx-auto text-center mt-32">
            <h1 className="text-soft-green text-6xl">Politica de privacidade e tratamento de dados pessoais</h1>
            <p className="text-center text-soft-green text-2xl my-32 w-1/2 mx-auto">Porque a tua privacidade é importante para nós, apenas usamos os dados recolhidos de acordo com a legislação aplicável sobre proteção de dados pessoais - Lei 67/98 de 26 de Outubro, Lei de Proteção de Dados - para que deste modo seja assegurada a segurança e confidencialidade. Não vendemos nem cedemos estas informações a terceiros. Garantimos sempre o teu direito de acesso, esquecimento, retificação e/ou oposição ao uso das tuas informações.</p>
        </div>
    )

}

export default Politica;