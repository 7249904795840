import React from 'react';
import logo from "../images/logo.webp"

const BreathingLogo = () => {
  return (
    <div className='flex items-center justify-center breathe-logo'>
      <img src={logo} alt="Logo" className="w-48 h-auto" />
    </div>
  );
};

export default BreathingLogo;