import React from 'react';
import BreathingLogo from '../components/BreathingLogo';

const LoadingScreen = () => {
  return (
    <div className='flex items-center justify-center bg-soft-green bg-opacity-60 h-screen w-screen z-50'>
        <BreathingLogo/>
    </div>
  );
};

export default LoadingScreen;